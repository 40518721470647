/**
 * main.js
 *
 * The client-side entry point.
 *
 */
var style = require('../sass/main.scss');
//var fonts = require('../css/google-fonts.css');
var AppView = require('./views/appView');
var appView = new AppView();

